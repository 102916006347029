import React, { useEffect, useState, useCallback } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"

import { el, enGB } from 'date-fns/locale'

import Calendar from "./../images/calendar.svg"

import htmlDecode from "html-entities-decoder";
import { format } from 'date-fns'
import Faqs from "../components/Service/Faqs"

import "../components/Blog/blog.css"
import '@wordpress/block-library/build-style/style.css'


function BlogPostTemplate(props) {
    let data = props.data;

    console.log(data.allWpPost.nodes[0].seo.breadcrumbs)

    let availableVersionsFiltered = props.pageContext.availablePages

    if (availableVersionsFiltered[0]) {
        let tmp = `${availableVersionsFiltered[0].language.slug === "en" ? `/${availableVersionsFiltered[0].language.slug}` : ""}/blog/${availableVersionsFiltered[0].slug}`

        availableVersionsFiltered[0].uri = tmp;
    }

    function constructMetaData(page, currentPage , language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr/${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : page.featuredImage.node.sourceUrl
        }
        return payload
    }

    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            breadcrumbs={data.allWpPost.nodes[0].seo.breadcrumbs}
            locale={props.pageContext.language}
            metaData={constructMetaData(data.allWpPost.nodes[0], props.pageContext.currentPage, props.pageContext.language)}

        >
            <article className="m-auto" style={{ maxWidth: "1170px" }}>
                <Link to={props.pageContext.language === "EL" ? '/blog' : `/en/blog`} className="flex items-center relative py-2 pl-4" style={{fontWeight: "600"}}>
                    ← Όλα τα άρθρα
                </Link>
                <div className="container-cs mx-auto py-16 md:py-16 px-4 flex flex-wrap">
                    <div className="w-full md:w-2/3">
                        <GatsbyImage
                            image={data.allWpPost.nodes[0].featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                            alt={data.allWpPost.nodes[0].featuredImage.node.altText}
                            className="m-auto sc-m-img0"
                        />
                        <h1 className="service-h1">{data.allWpPost.nodes[0].title}</h1>
                        <div className="service-editor" dangerouslySetInnerHTML={{ __html: data.allWpPost.nodes[0].content }} />
                    </div>
                    <div className="w-full md:w-1/3 pl-0 md:pl-8 pt-16 md:pt-0">
                        <h3 className="side-cat-h3 ml-0 md:ml-4">
                            {props.pageContext.language === "EL" ? "Νεότερα Άρθρα" : "New Articles"}
                        </h3>
                        {data.relatedPosts.nodes.map((post, i) => {
                            return (
                                <article key={`program-post-${i}`} className='w-full pb-8 px-4'>
                                    <a href={`${props.pageContext.language === "EN" ? "/en" : ""}${post.uri}`}>
                                        <div className='prog-wrapper'>
                                            <GatsbyImage
                                                image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                                alt={post.featuredImage.node.altText}
                                                className="m-auto sc-m-img0"
                                            />
                                            <div className="blog-con-wrapper">
                                                <div>
                                                    {(post?.tags && post.tags?.nodes?.length > 0) && post.tags.nodes.map((tag, j) => (
                                                        <React.Fragment>
                                                            <span className='program-tag' key={`program-tag-${j}`}>{tag.name}</span>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                                <h3 className="pt-4">{post.title}</h3>

                                                <div className="w-full z-10">
                                                    <div className="flex  blog-info pt-2">
                                                        <div className="flex justify-center">
                                                            <div>
                                                                <img src={Calendar} style={{ width: "15px", marginRight: "5px", paddingTop: "2px" }} />
                                                            </div>
                                                            <div className="blog-date">{props.pageContext.language === "EL" ? format(new Date(post.date), 'dd MMMM, yyyy', { locale: el }) : format(new Date(post.date), 'dd MMMM, yyyy', { locale: enGB })}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </article>
                            );
                        })}
                    </div>
                </div>
            </article>

            {(data.allWpPost.nodes[0].postsAcf.faqs && data.allWpPost.nodes[0].postsAcf.faqs?.title) && (
                <section>
                    <Faqs
                        data={data.allWpPost.nodes[0].postsAcf.faqs}
                    />
                </section>
            )}

        </Layout>
    );
}

export default BlogPostTemplate


export const pageQuery = graphql`query BLOG_POST_QUERY($id: String,  $templateId: String, $language: WpLanguageCodeEnum) {

    allWpPost(filter: {id:{eq: $id }} ) {
        nodes {
            slug
            title
            content
            date
            author {
                node {
                    name
                    firstName
                    avatar {
                        url
                    }
                }
            }
            seo {
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                    sourceUrl
                }
                breadcrumbs {
                    text
                    url
                }
            }
            featuredImage {
                node {
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                        }
                    }
                }
            }

            postsAcf {
                faqs {
                    title
                    faqs {
                        question
                        answer
                    }
                }
            }
        }
    }

    relatedPosts: allWpPost(
        limit: 3,
        sort: { fields: [date] , order: DESC },
        filter: {language: {code: {eq: $language}}}
      ) {
        nodes {
            uri
            slug
            title
            date
            excerpt

            featuredImage{
                node{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }

            tags{
                nodes{
                    name
                }
            }
            
        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
                workingHours {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
